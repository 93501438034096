<template>
  <div>
    <list-template
        :loading="loading"
        :total="total"
        :current-page="page"
        :table-data="tableData"
        :table-config="tableConfig"
        :search-config="searchConfig"
        :buttons-text="buttonsText"
        :search-buttons="['search','reset']"
        @onChangePage="changeCurrentPage"
        @onSearch="onSearch"
        @onHandle="tableHandle"
        @onReset="search = null;onReset()"
        ref="table"
    >
    </list-template>
    <!--  学习习惯检查情况的弹出框  -->
    <el-dialog
        :visible.sync="dialogVisible"
        :title="'学习习惯检查情况'">
      <el-table
          :data="studyHabit"
          style="width: 100%">
        <el-table-column
            v-for="item in dialogTableConfig"
            :prop="item.prop"
            :label="item.label">
        </el-table-column>
        <el-table-column label="任务实时完成率">
          <template slot-scope='scope'>
            <span>{{ Number(scope.row.rate) ? scope.row.rate + '%' : 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="完成状态">row.rate ? rate + '%' : rate
          <template slot-scope='scope'>
            <span v-if="scope.row.status_name === '未完成'" style="color: red">{{ scope.row.status_name }}</span>
            <span v-else>{{ scope.row.status_name }}</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["userInfo"])
  },
  data() {
    return {
			loading: true,
			// 搜索框的选中数据
			search: null,
			// 数据总量
			total: 0,
			searchConfig: [
				{
					prop: "month",
					tag: "select",
					placeholder: "请选择年月",
					options: [],
					// property: {
					// 	size: "small",
					// 	style: "margin-right:20rem;",
					// 	type: "month",
					// 	valueFormat: "yyyyMM",
					// 	disabledDate: this.disabledDate, // this.disabledDate = ['202303', '202304', '202305', '202308']    注意this.disabledDate是可变的
					// 	pickerOptions: {
					// 		disabledDate: time => {
					// 			const yearMonth = time.getFullYear() + String(time.getMonth() + 1).padStart(2, '0');
					// 			return !this.disabledDate.includes(yearMonth)
					// 		}
					// 	}
					// }
				},
				{
					prop: "subject_id",
					placeholder: "请选择科目",
					tag: "select",
					options: [],
					label: 'name',
					value: 'subject_id',
					change: (val, search) => {
						if (search.school_id) {
							this.search = search
							let params = {
								subject_id: search.subject_id,
								school_id: search.school_id
							}
							if (search.grade_id) {
								params.grade_id = search.grade_id
							}
							this.$_axios2.get('api/common/school', {params}).then(res => {
								let data = res.data.data;
								this.searchConfig[5].options = data.teaching_data;
							})
						}
					}
				},
				{
					prop: "status",
					placeholder: "请选择完成状态",
					tag: "select",
					options: [
						{value: 0, label: "未完成"},
						{value: 1, label: "已完成"},
					]
				},
				{
					prop: 'school_id',
					tag: 'select',
					placeholder: '请选择校区',
					options: [],
					label: 'name',
					value: 'id',
					change: (val, search) => {
						this.search = search
						this.search.grade_id = ''
						this.search.teaching_id = ''
						let params = {
							school_id: search.school_id
						}
						if (search.subject_id) {
							params.subject_id = search.subject_id
						}
						this.$_axios2.get('api/common/school', {params}).then(res => {
							let data = res.data.data;
							this.searchConfig[4].options = data.grade;
							this.searchConfig[5].options = data.teaching_data;
							if (data.subject_data.length) {
								let boo = false
								data.subject_data.forEach(item => {
									if (item.subject_id === search.subject_id) boo = true
								})
								if (!boo) {
									this.search.subject_id = ''
								}
							} else {
								this.search.subject_id = ''
							}
						})
					}
				}, {
					prop: 'grade_id',
					tag: 'select',
					placeholder: '请选择年级',
					options: [],
					label: 'name',
					value: 'id',
					change: (val, search) => {
						this.search = search
						this.search.teaching_id = ''
						let params = {
							school_id: search.school_id,
							grade_id: search.grade_id
						}
						if (search.subject_id) {
							params.subject_id = search.subject_id
						}
						this.$_axios2.get('api/common/school', {params}).then(res => {
							let data = res.data.data;
							this.searchConfig[1].options = data.subject_data;
							this.searchConfig[5].options = data.teaching_data;
							if (data.subject_data.length) {
								let boo = false
								data.subject_data.forEach(item => {
									if (item.subject_id === search.subject_id) boo = true
								})
								if (!boo) {
									this.search.subject_id = ''
								}
							} else {
								this.search.subject_id = ''
							}
						})
					}
				}, {
					prop: 'teaching_id',
					tag: 'select',
					placeholder: '请选择班级',
					options: [],
					label: 'name',
					value: 'id',
					change: (val, search) => {
						if (!search.subject_id) {
							this.search = search
							let params = {
								school_id: search.school_id,
								grade_id: search.grade_id,
								teaching_id: search.teaching_id,
							}
							this.$_axios2.get('api/common/school', {params}).then(res => {
								let data = res.data.data;
								this.searchConfig[1].options = data.subject_data;
								if (data.subject_data.length) {
									let boo = false
									data.subject_data.forEach(item => {
										if (item.subject_id === search.subject_id) boo = true
									})
									if (!boo) {
										this.search.subject_id = ''
									}
								} else {
									this.search.subject_id = ''
								}
							})
						}
					}
				},
				{
					prop: "keyword",
					placeholder: "请输入姓名搜索"
				},
			],
			tableData: [],
			tableConfig: [
				{width: "80rem", label: '序号', type: 'number', textAlign: 'left', fixed: true},
				{prop: "teacher_info", label: "教师姓名", fixed: true},
				{prop: "subject_info", label: "检查科目"},
				{prop: "teaching_info", label: "检查班级"},
				{prop: "student_total", label: "学生总数",},
				{prop: "month", label: "检查年月",},
				{prop: "grade_info", label: "年级",},
				{prop: "school_info", label: "校区",},
				{
					prop: "status_name", label: "完成状态", fixed: 'right', render: (row) => {
						return row.status === 0 ? `<span style="color:red">未完成</span>` : "已完成"
					}
				},
				{
					prop: "",
					label: "检查情况",
					fixed: 'right',
					handle: true,
					showOverflowTooltip: false,
          buttons:[{
            type:"view",
            text:"详情"
          }],
					buttons: [{ type:"view", text:"详情" }]
				},
			],
			dialogVisible: false,
			studyHabit: [],
      buttonsText:{
        add:"添加"
      },
			dialogTableConfig: [
				{prop: "knack_info", label: "学习习惯"},
				{prop: "month", label: "检查年月"},
				{prop: "teacher_info", label: "检查老师"},
				{prop: "teaching_total", label: "学生人数"},
				{prop: "task_total", label: "检查任务"},
				{prop: "accomplish_total", label: "已完成任务人数"},
				{prop: "accomplish_no_total", label: "未完成任务人数"}
			]
		}
	},
	mounted() {
		this.$store.commit("setPage", 1);
		this.commonInterface(); // 获取可筛选项
		// this.getSubjectData(); // 获取科目
		this.getData(); // 获取数据

		this.$_axios2.get('api/educational-routine/month-select').then(res => {
			this.searchConfig[0].options = res.data.data.map(item => {
				return {
					label: item,
					value: item
				}
			})
		})
	},

	methods: {
		...mapMutations(["setPage"]),
		commonInterface() {
			this.$_axios2.get('api/common/school').then(res => {
				let data = res.data.data
				this.searchConfig[1].options = data.subject_data;
				this.searchConfig[3].options = data.school;
			})
		},
		// 获取列表基础数据
		getData() {
			this.loading = true
			this.$_axios2.get("api/educational-routine/educational-routine-list", {
				params: {
					...this.search,
					page: this.page
				}
			}).then(res => {
				let data = res.data.data
				if (!(Array.isArray(data))) {
					this.tableData = data.list
					this.total = data.page.total
				} else {
					this.tableData = []
				}
			}).catch().finally(() => {
				this.loading = false
			})
		},
		changeCurrentPage(e) {
			this.setPage(e)
			this.getData();
		},
		onSearch(val) {
			this.setPage(1);
			this.search = val
			this.getData();
		},
		// 表格后面操作被点击
		tableHandle(row) {
      this.getStudyHabit(row.id);
      this.dialogVisible = true
		},
		// 学习习惯检查情况
		getStudyHabit(id) {
			this.$_axios2.get("api/educational-routine/educational-routine-detail?task_id=" + id).then(res => {
				this.studyHabit = res.data.data;
			})
		},

		onReset() {
			this.searchConfig[4].options = []
			this.searchConfig[5].options = []
      this.commonInterface()
		}
	}
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  width: 80% !important;
}
</style>
